<template>
  <div class="news-page">
    <!-- 会员单位介绍 -->
    <main-nav />

    <div class="news-bar">
      <div class="bannerWrapper">
        <img src="../../assets/B2.jpg" alt="" />
      </div>
    </div>
    <div class="newsWrapper">
      <div v-for="(item, index) in resdata" :key="index" class="newsItem">
        <div class="newsLeftItem">
          <img
            v-if="item.contentImg.length > 0"
            :src="'https://admin.gdhhsh.cn' + item.contentImg[0].path"
            alt=""
          />
        </div>
        <div class="newsRightItem" @click="toDetail(item)">
          <p>{{ item.contentTitle }}</p>
          <div class="ItemContent newsOmit">{{ item.contentDescription }}</div>
          <div class="css_time">{{ item.contentDatetime }}</div>
        </div>
      </div>
    </div>

    <back-to-top
      :custom-style="myBackToTopStyle"
      :visibility-height="300"
      :back-position="0"
      transition-name="fade"
    />

    <Footer />
  </div>
</template>

<script>
import Qs from "qs";
import { Memberstyle, Newsdetail } from "@/api/fetch";
import MainNav from "../../components/MainNav/index";
import Footer from "../../components/Footer.vue";
export default {
  name: "eleDem",
  data() {
    return {
      pagenum: 1,
      resdata: [],
      typeMark: 0, // 默认为 供应
      currentPage: 1, // 当前页
      stripNum: 6, // 页面条数
      myBackToTopStyle: {
        right: "50px",
        bottom: "50px",
        width: "6rem",
        height: "6rem",
        "border-radius": "50%",
        "line-height": "45px", // 请保持与高度一致以垂直居中
      },
    };
  },
  components: {
    MainNav,
    Footer,
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      // this.initScroll()
      window.onscroll = function () {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight) {
          //写后台加载数据的函数 一定要用that
          that.pagenum++;
          that.newsAPIlist();
        }
      };
    });
    this.newsAPIlist();
  },
  methods: {
    newsAPIlist() {
      let obj = {
        pageNo: this.pagenum,
        pageSize: "10",
        categoryId: "13",
        contentDisplay: "0",
      };
      Memberstyle(Qs.stringify(obj)).then((res) => {
        res.data.data.rows.forEach((element) => {
          element.contentImg = JSON.parse(element.contentImg);
          this.resdata.push(element);
        });
      });
    },
    // 点击跳转详情页
    toDetail(item) {
      let obj = {
        id: item.id,
        contentDisplay: "0",
      };
      Newsdetail(obj).then((res) => {
        let strItem = JSON.stringify(item);
        this.$router.push({
          name: "News1",
        });
        this.$router.push(
          `/News1/` + encodeURIComponent(strItem).replace(/%/g, "%25")
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.css_time {
  position: absolute;
  bottom: 0px;
  right: 24px;
}
@import "../../styles/news";
.news-page {
  .news-bar {
    height: auto;
    position: relative;
    margin-bottom: 2%;
    .bannerWrapper {
      width: 100%;
      height: 500px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .pagenav {
    margin-top: 2.71%;
    margin-bottom: 4.67%;
    text-align: center;
    .pagination {
      li {
        .active {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
        a {
          margin-right: 0.83rem;
        }
        a:hover {
          border-color: #2b8dfa;
          background-color: #ffffff;
        }
        a:focus {
          background-color: #2b8dfa;
          border-color: #2b8dfa;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .news-page .news-bar /deep/.newsListNav {
    height: 6rem;
    .ListNav/deep/ {
      margin-right: 15rem;
    }
  }
}
</style>
